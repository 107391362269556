import { render, staticRenderFns } from "./cafeterias.vue?vue&type=template&id=95b9439e&scoped=true&"
import script from "./cafeterias.vue?vue&type=script&lang=js&"
export * from "./cafeterias.vue?vue&type=script&lang=js&"
import style0 from "../../templateAssests/css/listing.css?vue&type=style&index=0&id=95b9439e&scoped=true&lang=css&"
import style1 from "../../templateAssests/css/detail-page.css?vue&type=style&index=1&id=95b9439e&scoped=true&lang=css&"
import style2 from "./cafeterias.vue?vue&type=style&index=2&id=95b9439e&scoped=true&lang=css&"
import style3 from "./cafeterias.vue?vue&type=style&index=3&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95b9439e",
  null
  
)

export default component.exports