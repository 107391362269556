<script>

export default {  
  props: ["restid"],
    data() {
       return {
           sugesstionArr: [],
           userSearchKey:'',
       } 
    },
    mounted:{
        
    },
    methods:{
        getSearchSugestions()
        {
            if(this.userSearchKey.length >= 3)
            {
                var latitude = '';
                    var longitude = '';
                if (JSON.parse(localStorage.getItem('placesAddress')) !== null) {
                     latitude = JSON.parse(localStorage.getItem('placesAddress')).latitude;
                    longitude = JSON.parse(localStorage.getItem('placesAddress')).longitude;
                }
                let serachMenuData = JSON.stringify({ searchKeyword: this.userSearchKey,
                                                      latitude: latitude,
                                                      longitude: longitude,
                                                      restBranchId: this.restid,
												});

                this.axios.post('/serachMenuData', serachMenuData)
                .then((res)=>{
                    // console.log(res);
                    let status = res.status;
                    if(status == 200 && res.data.status==200)
                    {
                        this.sugesstionArr = res.data.searchResult;
                    }else{
                        alert(res.data.message);
                    }
                });
            }
        }
    }
}
</script>

<template>
    <div  class="container-fuild m-0 p-0">
        <b-modal
                  id="modal-searchBox"
                  size="lg"
                  title="Search"
                  title-class="font-18"
                  hide-footer
                >
                 <div class="form-group bxSearchBox">
                    <div class="inner-addon right-addon">
                        <i class="bx bx-search bxSearchModal"></i>
                        <input type="text" v-model="userSearchKey" @input="getSearchSugestions" class="form-control userSearchInput" placeholder="Search Restaurants, Speciality, Dishes"/>
                    </div>
                </div>
                <div class="form-group suggestionBoxMain" v-if="sugesstionArr.length > 0">
                    <div class="inner-addon right-addon sugesstionBox">
                        <div class="searchCardDiv" v-for="(searchRes,i) in sugesstionArr" v-bind:key="i">
                            <div v-if="searchRes.searchCardType== 'Restaurant'">
                                <router-link :to="{ name: 'restaurantDetailByShort', params: { id: searchRes.restIdentifierString} }">   
                                    <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                    <p class="searchCardTitle">{{searchRes.restName}} - {{searchRes.branchName}}</p>
                                    <span class="searchCardType">{{searchRes.searchCardType}}</span>
                                </router-link>
                            </div>

                            <div v-if="searchRes.searchCardType== 'Speciality'">
                            <a :href="$router.resolve({ name: 'restaurantCollections', params: { type:'Speciality',id: searchRes.cuisineName } }).href">
							    <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                <p class="searchCardTitle">{{searchRes.cuisineName}}</p>
                                <span class="searchCardType">{{searchRes.searchCardType}}</span>
                            </a>
                            </div>

                            <div v-if="searchRes.searchCardType== 'Dish'">
                            <a :href="$router.resolve({ name: 'restaurantCollections', params: { type:'Dish',id: searchRes.dishName } }).href">
							    <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                <p class="searchCardTitle">{{searchRes.dishName}}</p>
                                <span class="searchCardType">{{searchRes.searchCardType}}</span>
                            </a>
                            </div>
                            <hr class="hrBorder">
                        </div>
                    </div>
                </div>
        </b-modal>
    </div>
</template>
<style scoped>
.inner-addon {
        position: relative;
    }
    .bxSearchBox
    {
        margin-bottom: 0px;
    }
    .bxSearchModal{
        position: absolute;
        padding: 10px;
        pointer-events: none;
    }
.right-addon .bxSearchModal { right: 8px;font-size:22px;}
.right-addon input { padding-right: 30px; border-radius: 4px;}
.sugesstionBox
{
    /* max-height: 20rem; */
    border-radius: 4px;
    /* position: absolute; */
    overflow: auto;
    /* top: 6rem; */
    visibility: visible;
    opacity: 1;
    border: 1px solid #f0f0f0;
    /* box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px; */
    transition: opacity 0.25s ease 0s, top 0.25s ease 0s;
    padding: 15px;
}
/* .cartTotalItemDetails::-webkit-scrollbar 
{
    display: none;
} */
.sugesstionBox::-webkit-scrollbar
{
    width: 3px;
    background-color: #F5F5F5;
}

.sugesstionBox::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #e0e0e0;
    border: 2px solid #e0e0e0;
}
.searchCardDiv
{
    /* display: flex;
    position: relative; */
    background: rgb(255, 255, 255);
    padding: 5px 5px;
    min-height: 75px;
    max-height: 80px;
}
.searchCardTitle
{
    margin-bottom: 3px;
    font-size: 15px;
    color: #000000;
}
.searchCardType
{
    font-size: 13px;
    color: #F3766A;
}
.userSearchInput
{
    border: 1px solid #f0f0f0;
}
@media (max-width: 991px){
		.userSearchInput
		{
			padding: 26px 20px;
		}
        .bxSearchModal
        {
            background: #F3766A;
            color: #fff;
            border-radius: 5px;
            margin-top: 5px;
        }
	}
</style>
<style>
#modal-searchBox___BV_modal_body_
{
    padding: 30px;
}
#modal-searchBox___BV_modal_outer_ div#modal-searchBox div.modal-dialog
{
    height: 100%;
}
#modal-searchBox___BV_modal_outer_ div#modal-searchBox div.modal-dialog div.modal-content
{
    min-height: 100%;
}

.modal-header .close, .alert .close
{
    padding: 0px!important;
}
.modal-header .close
{
    margin: 15px!important;
    padding: 0px!important;
}
</style>