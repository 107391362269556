<script>
import footerLayout from "../../layouts/footerLayout";
import headerLayout from "../../layouts/headerLayout";
import commonApi from "../apiModel/apiModel";

import Multiselect from "vue-multiselect";

import searchBoxPopup from "../../pages/popUpModals/searchBoxPopup";

import 'bootstrap-vue/dist/bootstrap-vue.css'

// Add class when scrolled
import ClassOnScroll from 'vue-class-on-scroll'


// carousel
import carousel from 'vue-owl-carousel'

// Expandable
import {
	VsaList,
	VsaItem,
	VsaHeading,
	VsaContent,
	VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

import Vue from 'vue'

// make any element sticky
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'


// input cart - 1 + 
import { IntegerPlusminus } from 'vue-integer-plusminus'
 
// For Scroll Spy (lunch,Startes)
import VueScrollactive from 'vue-scrollactive';
 
Vue.use(VueScrollactive);

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
Vue.use(VueCustomTooltip)



// Vue.use(VueCustomTooltip, {
//   name: 'VueCustomTooltip',
//   color: '#fff',
//   background: '#000',
//   borderRadius: 12,
//   fontWeight: 400,
// })

import { VuePicker, VuePickerOption } from '@invisiburu/vue-picker'

import '@invisiburu/vue-picker/dist/vue-picker.min.css'

Vue.component('VuePicker', VuePicker)
Vue.component('VuePickerOption', VuePickerOption)

// import VueHorizontalList from 'vue-horizontal-list';
	// import VueDropdown from 'vue-dynamic-dropdown'

// 	  can register the component globally By adding this in main.js

// Vue.component('vue-dropdown', VueDropdown);

export default {
	data() {
		return {
			cusineAddonModalShow:true,
			selectedAddonCartItems:[],
			itemTotalCartModal:'',
			cusinieAddonsDetails: "",
			sugesstionArr: [],
			userSearchKey:'',
			bannerDetailsArr: [],
			bannerShimmer: 0,
			list:[{categoryid:0}], //cafeteria menu list
			cusineDetailsDataArr:[], //single cusine data,
			cusineTypeIndex: undefined, // index of cusine Type data,
			cusineItemIndex: undefined, // index of cusine Item,
			cusineModalShow: true, // cusine modal hide show
			itemInCartArr: [], // item that are added in cart 
			cartDetails: [], // cart count total price etc.
			cartItemsDetails: [], // all the dishes in cart
			cuisineItemDetails: [],
			selectedAddOnItems:[],
			licenseNO:'',
			allFoodTypes:[],
			itemFromModal: 1,
			itemFromCard: 1,
			cartQuantity: 1,
			cartStatus: undefined, // get cart status with api
			selectFoodType: '',
			addOnsData: undefined,
			getAllMealTypesTabs:[],
			Shimmer: 1,
			userID: '',
			corporateBranchID: '',
			itemTotalPopup:'',
			cafeteriasForCorp:[],
			cafeRestBranchID:[],
		  	restaurantBranchIDForCafe:'',
			getMenuRestBranchID: '',
			loadMealTypePageAccess: 0,
		  	navIcons :['<i class="bx bx-chevron-left"></i>', '<i class="bx bx-chevron-right"></i>'],
			show: false,
			variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
			// headerBgVariant: 'dark',
			// headerTextVariant: 'light',
			// bodyBgVariant: 'light',
			// bodyTextVariant: 'dark',
			footerBgVariant: 'white',
			footerTextVariant: 'dark',
			options: {
				topSpacing: 95,
				resizeSensor: true,
				stickyClass: "is-affixed",
				//   bottom: 0,
			},
      	}
    },
	
	mounted()
    {
		this.getUserData();
		this.getAllCafeterias();

		// console.log(this.$userData.userID);
		// axios.post('api/api1/cafeteriamenu', formData,{
		//             headers: {
		//             'Access-Control-Allow-Origin': '*',
		// 			"Content-Type": "text/html",
		// 			"Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept"
		//             }
		//             })	
		if (localStorage.getItem('mealTypeTabsData') !== null) {
			this.getAllMealTypesTabs = JSON.parse(localStorage.getItem('mealTypeTabsData')).mealTypeTabs;
			this.getAllMealTypesTabs.findIndex((element, restBranchIndex) => {
				if (element.menuTabType === 'cafeteria') {
					this.restBranchIndex = restBranchIndex;
				}
			});
			// console.log(this.cafeIndex);
			this.cafeRestBranchID.restBranchID = this.getAllMealTypesTabs[this.restBranchIndex].restBranchID;
		}

		if (this.$userData !== null) {
			// this.userID = this.$userData.userID;
			// this.corporateBranchID = this.$userData.corporateBranchID;
			// this.isCorporateUser = JSON.parse(localStorage.getItem('userData')).isCorporateUser;
			// this.corporateBranchID = JSON.parse(localStorage.getItem('userData')).corporateBranchID;

			this.getCartDetails();
		}

		// this.getCafeMenuByID();  
		this.getBannerByMealType();
		// this.getAllCafeterias();
		// this.getCartStatus();
    },

	// computed: {
    //     isInArray: function() {
    //         return this.itemInCartArr.includes(value);
    //     }
    // },

	methods: {
		// checkAddonReq(addonsArr,cusineDataArr, typeIndex, cusineIndex, isItemFromModal)
		// {
		// 	// console.log(addonsArr);

		// // 	// create empty array to fill with values from example array
		// 	let resultArray = [];
		// 	this.selectedAddOnItems.map( obj => resultArray.push( obj.addonGroupID ) )
		// 	// console.log( resultArray ) //  [13, 13, 14, 14, 15]

		// 	// create empty object to populate with property and value later
		// 	let selectedArr = [];
		// 	let count = {};
		// 	resultArray.forEach( val  =>
		// 	{
		// 		count[val] = ++count[val] || 1
		// 	} )
		// 	// console.log(count) // {13: 2, 14: 2, 15: 1}

		// 	Object.keys(count).forEach(key => {
		// 		// console.log('key', key);     
		// 		// console.log('value', count[key]);   
		// 		let selectedAddonsCount = {addonGroupID:key, numOfAddOnSelected:count[key]}; 
		// 		selectedArr.push(selectedAddonsCount); 
		// 	});
		// // console.log(selectedArr);
		// 	if(addonsArr.length > 0)
		// 	{
		// 		for (var i = 0; i < addonsArr.length; i++) 
		// 		{
		// 			let addOnsSection = addonsArr[i];
		// 			let numberOfChoice = addOnsSection.selectionType ? addOnsSection.selectionType : 0;
		// 		}
		// 	}

		// 	var addonID = null;
		// 	var itemCnt = 0;
		// 	for (var i = 0; i < this.selectedAddOnItems.length; i++) {
		// 		if (this.selectedAddOnItems[i].addonGroupID != addonID) {
		// 			if (itemCnt > 0) {
		// 				var resultArr = {
		// 					'addonID' :addonID,
		// 					'itemCount':itemCnt
		// 				}
		// 				console.log(addonID + ' comes --> ' + itemCnt + ' times1<br>');
		// 			}
		// 			addonID = this.selectedAddOnItems[i].addonGroupID;
		// 			itemCnt = 1;
		// 		} 
		// 		else {
		// 			itemCnt++;
		// 		}
		// 	}
		// 	if (itemCnt > 0) {
		// 		console.log(addonID + ' comes --> ' + itemCnt + ' times2');
		// 	}
		// 	return;
		// 	//this.addItemToCart(cusineDataArr, typeIndex, cusineIndex, isItemFromModal);
		// },

		addOnsModal(cartID, cuisineID, restBranchID)
		{
			let getCuisineDetails = JSON.stringify({ 
				cartID: cartID,
                cuisineID: cuisineID,
                restBranchID: restBranchID
				});
			this.axios.post('/getCuisineDetailsForCart', getCuisineDetails)
			.then((response)=>{
			// console.log(response.data.cuisineDetails);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
                this.cusinieAddonsDetails = response.data;

				/////////// to get total sum of item from cart ///////////////
				this.itemFromCartModal = this.cusinieAddonsDetails.cuisineDetail.quantity;
				this.selectedAddonCartItems = response.data.cartSelectedAddonsArr;
				var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
				this.itemTotalCartModal = (parseInt(this.cusinieAddonsDetails.cuisineDetail.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
				/////////// to get total sum of item from cart ///////////////
                this.$bvModal.show("modal-cuisinePopup");
            }
            }).catch((error) => {
			    console.log(error.response.data.message);
			});
		},

		selectCartModalAddons(itemPrice)
		{
			// console.log(this.selectedAddonCartItems);
			// console.log(itemPrice);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(itemPrice) + parseInt(sum)) * parseInt(this.itemFromCartModal);
		},

		updateCartDataFromModal(cusineDetailsDataArr)
		{
			// console.log(cusineDetailsDataArr);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(cusineDetailsDataArr.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
		},

		updateItemAddonModal(cusineDetailsDataArr)
		{
			this.cusineAddonModalShow = false;
			// console.log(cusineDetailsDataArr);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(cusineDetailsDataArr.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
			this.addItemToCartCommonApi = commonApi.addItemTocartApi;
			this.arrayColumn = commonApi.arrayColumn;
			var selectedAddonCartItemsIDArr = this.arrayColumn(this.selectedAddonCartItems, 'addOnItemID');

			var newQuantity = this.itemFromCartModal;
			var itemDishID = cusineDetailsDataArr.id;
			var restBranchID = cusineDetailsDataArr.restaurantid;

			if(this.itemFromCartModal < cusineDetailsDataArr.minorderquantity)
			{
				this.$confirm("Are you sure, you want to remove this item from cart. Minimum order quantity requires "+ cusineDetailsDataArr.minorderquantity+ "","Remove Item?","")
				.then(() => {
								var newQuantity = 0;
								let getCartDetailsData = JSON.stringify({ 
																		userID: this.userID,
																		dishID: itemDishID,
																		quantity: newQuantity,
																		restBID: restBranchID,
																		addOnItems:selectedAddonCartItemsIDArr,
																		});
								this.selectedAddOnItems = []; // null value of previously selected addons
								Vue.axios.post('/updateCart', getCartDetailsData)
									.then((response)=>{
										// console.log(response);
										let status = response.status;
										if(status == 200 && response.data.status == 200)
										{
												// this.getCheckoutDetails(this.coupon, 0);
												// this.getCheckoutDetails();
												// this.getCafeMenuByID();
												location.reload();
										}
										// this.cartStatus = response.data.cartdetails;
									}).catch((error) => {
											console.log(error.response.data.message);
									});
				})
				.catch(error => {
						cusineDetailsDataArr.quantity = cusineDetailsDataArr.minorderquantity;
						});
			}
			else
			{
				let getCartDetailsData = JSON.stringify({ userID: this.userID,
														dishID: itemDishID,
														quantity: newQuantity,
														restBID: restBranchID,
														addOnItems:selectedAddonCartItemsIDArr,
															});
			  					this.selectedAddOnItems = []; // null value of previously selected addons
								Vue.axios.post('/updateCart', getCartDetailsData)
									.then((response)=>{
										// console.log(response);
										let status = response.status;
										if(status == 200 && response.data.status == 200)
										{
												// this.getCheckoutDetails(this.coupon, 0);
												// this.getCheckoutDetails();
												// this.getCafeMenuByID();
												location.reload();
										}
										// this.cartStatus = response.data.cartdetails;
									}).catch((error) => {
											console.log(error.response.data.message);
									});
			}
		},

		getSearchSugestions()
        {
            if(this.userSearchKey.length >= 3)
            {
				console.log(this.cafeRestBranchID.restBranchID);
                var latitude = '';
                    var longitude = '';
                if (JSON.parse(localStorage.getItem('placesAddress')) !== null) {
                    latitude = JSON.parse(localStorage.getItem('placesAddress')).latitude;
                    longitude = JSON.parse(localStorage.getItem('placesAddress')).longitude;
                }
                let serachMenuData = JSON.stringify({ searchKeyword: this.userSearchKey,
                                                      latitude: latitude,
                                                      longitude: longitude,
													  restBID: this.cafeRestBranchID.restBranchID,
												});

                this.axios.post('/serachMenuData', serachMenuData)
                .then((res)=>{
                    // console.log(res);
                    let status = res.status;
                    if(status == 200 && res.data.status==200)
                    {
                        this.sugesstionArr = res.data.searchResult;
                    }else{
                        alert(res.data.message);
                    }
                });
            }
        },
		checkCafePage(menuTab,restBranchID)
		{
			if(menuTab=="cafeteria")
			{
				this.cafeRestBranchID.restBranchID = restBranchID;
				this.getCafeMenuByID();
			}
			
		},

		changeCafe()
		{
			this.getCafeMenuByID();  
		},
		
		selectAddons(itemPrice)
		{
			// console.log(this.selectedAddOnItems);
			var sum = this.selectedAddOnItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalPopup = (parseInt(itemPrice) + parseInt(sum)) * parseInt(this.itemFromModal);
		},
		changeFoodType()
		{
			this.cafeRestBranchID.restBranchID = this.restaurantBranchIDForCafe;
			// alert(this.cafeRestBranchID.restBranchID);
			this.getCafeMenuByID();
		},

		makeToast()
		{
			this.$bvToast.toast('Toast body content', {
				title: `Variant `,
				variant: danger,
				solid: true
			})
		},
		
		getCafeMenuByID()
		{
			// this.getAllCafeterias();

			if (this.getMenuRestBranchID == "" && this.loadMealTypePageAccess == 0) {	
				this.getMealTypePageAccess();	
			}	
			console.log('cafeRestBranchID - ' + this.getMenuRestBranchID)

			this.Shimmer = 0;
			let cafeteriaMenuFormData = JSON.stringify({ 
				// userID: this.userID,
				// corporateBranchID: this.corporateBranchID,
				// restBranchID:this.cafeRestBranchID.restBranchID,
				restBranchID: this.getMenuRestBranchID ? this.getMenuRestBranchID : 0,
				foodTypeID: this.selectFoodType
			});

			Vue.axios.post('/getCafeMenuByCorporateID', cafeteriaMenuFormData)
			.then((res)=>{
				let status = res.status;
				if(status == 200 && res.data.status==200)
				{
					this.Shimmer = 1;
					this.list = res.data.data;
					this.licenseNO = res.data.licenseNO;
					this.restaurantBranchIDForCafe = res.data.restaurantBranchIDForCafe;
					this.allFoodTypes = res.data.allFoodTypes;
					// this.getBannerByMealType();
					this.getAllCafeterias();
				}else{
					// console.log(res.data.message);
					this.list = [];
					// alert(res.data.message);
					// this.$router.push('/');
				}
			});
		},

		getCusineDetails(cusineDataArr, typeIndex, cusineIndex)
		{
			this.getCusinieDetailsCommonApi = commonApi.getCusineDetailsApi;
			this.getCusinieDetailsCommonApi(cusineDataArr, typeIndex, cusineIndex);
			if(cusineDataArr.mealType == 2)
			{
				this.itemFromModal = cusineDataArr.minorderquantity;
			}
		},

		getCusineById(cusineId,cusineDataArr)
		{
			this.getCusinieByIDCommonApi = commonApi.getCusinieByIDApi;
			this.getCusinieByIDCommonApi(cusineId, this.userID, cusineDataArr);
		},

		addItemToCart(cusineDataArr, typeIndex, cusineIndex, isItemFromModal)
		{
			this.cusineModalShow = false;
			this.addItemToCartCommonApi = commonApi.addItemTocartApi;
			this.flushCartDataAndAddItemToCartCommonApi = commonApi.flushCartDataAndAddItemToCart;
			this.arrayColumn = commonApi.arrayColumn;
			var selectedAddOnItemsIDArr = this.arrayColumn(this.selectedAddOnItems, 'addOnItemID');
			// console.log(this.cartDetails[0].restBranchID);
				
			if(this.cartDetails.length != 0 && (cusineDataArr.restaurantid != this.cartDetails[0].restBranchID))
			{
				// 	// for confirm modal syntax is 1.message,2.title,3.type
				this.$confirm("Your cart contains items from Other restaurant. Would you like to reset your cart before adding this item?","Items already in cart","")
				.then(() => {
					// console.log(this.flushedcart);
					// 		// userID and restBranchID to flush previous cartData and cusineData Arr for add items to cart
					// this.flushCartDataAndAddItemToCartCommonApi(this.cartDetails[0].restBranchID, cusineDataArr.restaurantid);
					// this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, this.selectedAddOnItems);
					this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, selectedAddOnItemsIDArr, 0); // 0 meal type id for none
				});
			}
			else
			{
				// this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, this.selectedAddOnItems);
				this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, selectedAddOnItemsIDArr, 0); // 0 meal type id for none
			}
		},

		getCartDetails()
		{
			// let getCartDetailsData = JSON.stringify({ userID: this.userID,
			// 										});
			// Vue.axios.post('/getCartDetails', getCartDetailsData)
			Vue.axios.get('/getCartDetails')
			.then((response)=>{
				// console.log(response);
				let status = response.status;
				if(status == 200 && response.data.status == 200)
				{
					if(response.data.cartDetails !== '' && response.data.cartItems !== '')
					{
						this.loadCartDetails(response.data.cartDetails, response.data.cartItems);
					}
					else
					{
						this.cartDetails = [];
						this.cartItemsDetails = [];
					}
				}
				// this.cartStatus = response.data.cartdetails;
			}).catch((error) => {
					console.log(error.response.data.message);
			});
		},

		loadCartDetails(cartDetailsArr, cartItemsArr)
		{
			// console.log('in loadCartDetails');
			// console.log(cartDetailsArr);
			// console.log(cartItemsArr);
			this.cartDetails = cartDetailsArr;
			this.cartItemsDetails = cartItemsArr;
			// console.log(this.cartDetails);
			// console.log(this.cartItemsDetails);
		},
		updateItemToCartModal(cusineDetailsDataArr,itemData, typeIndex, cusineIndex, itemFrom)
		{
			var sum = this.selectedAddOnItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalPopup = (parseInt(cusineDetailsDataArr.price) + parseInt(sum) )* parseInt(this.itemFromModal);
			// this.itemTotalPopup = parseInt(this.itemTotalPopup) * parseInt(this.itemFromModal);
			
			// cusineDetailsDataArr.price = this.itemTotalPopup;

			this.updateItemToCart(itemData, typeIndex, cusineIndex, itemFrom);
		},
		updateItemToCart(cusineDataArr, typeIndex, cusineIndex, itemFrom) // itemFrom = 0=> from card, 1=>cart, 2=>modal 
		{	
			if(cusineDataArr.mealType == 2 && cusineDataArr.quantity < cusineDataArr.minorderquantity)
			{
				this.$confirm("Are you sure, you want to remove this item from cart. Minimum order quantity requires "+ cusineDataArr.minorderquantity+ " pax","Remove Item?","")
				.then(() => {
					this.arrayColumn = commonApi.arrayColumn;
					var selectedAddOnItemsIDArr = this.arrayColumn(this.selectedAddOnItems, 'addOnItemID');
					this.updateItemToCartCommonApi = commonApi.updateItemToCartApi;
					this.updateItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, itemFrom, selectedAddOnItemsIDArr);
				})
				.catch(error => {
						cusineDataArr.quantity = cusineDataArr.minorderquantity;
						});
			}
			else
			{
				this.arrayColumn = commonApi.arrayColumn;
				var selectedAddOnItemsIDArr = this.arrayColumn(this.selectedAddOnItems, 'addOnItemID');
				this.updateItemToCartCommonApi = commonApi.updateItemToCartApi;
				this.updateItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, itemFrom, selectedAddOnItemsIDArr);
			}
		},

		getBannerByMealType()
        {
			var restBranchID = localStorage.getItem('restBranchID') ? localStorage.getItem('restBranchID') : this.cafeRestBranchID.restBranchID;
            this.getBannerByMealTypeCommonApi = commonApi.getBannerByMealTypeApi;
            // this.getBannerByMealTypeCommonApi('cafeteria',this.restaurantBranchIDForCafe);
            this.getBannerByMealTypeCommonApi('cafeteria', restBranchID);
        },
		getAllCafeterias()
		{
			// let allCafeteriasFormData = JSON.stringify({ userID: this.userID,});

			// Vue.axios.post('/getAllCafeterias', allCafeteriasFormData)
			///changed on 21-01-2022
			var isExtMob =localStorage.getItem('isExtMob');
			var places= JSON.parse(localStorage.getItem('placesAddress'));
            var address = places.address;
			Vue.axios.get('/getAllCafeterias',{params: {"isExtMob":isExtMob,"address":address}})
			.then((res)=>{
				let status = res.status;
				if(status == 200 && res.data.status==200)
				{
					this.cafeteriasForCorp = res.data.data;
					// console.log(this.cafeteriasForCorp);
					var indexOfCafe = '';
					this.cafeteriasForCorp.findIndex((element, cafeIndex) => {
					if (element.restBranchID == this.restaurantBranchIDForCafe) {
							indexOfCafe = cafeIndex;
							this.indexCafe = indexOfCafe
							// alert(indexOfCafe);
						}
					});
					// console.log(this.indexOfCafe);
					// alert(this.cafeteriasForCorp[this.indexCafe].restBranchID);
					this.cafeRestBranchID = [{'restBranchID':this.cafeteriasForCorp[indexOfCafe].restBranchID,'restBranchName':this.cafeteriasForCorp[indexOfCafe].restBranchName}];
					// console.log(this.cafeRestBranchID);					
				}else{
					console.log(res.data.message);
					// alert(res.data.message);
					// this.$router.push('/');
				}
			});
		},

		getUserData()
    	{
        	this.axios.get('/getUserDeatils').then((response)=>{					
				let status = response.status;

				if (status == 200 && response.data.status == 200) {
					Vue.prototype.$isExtMob = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob :  0;
					localStorage.setItem('isExtMob',JSON.stringify(this.$isExtMob));

					if (response.data.userData.placesAddress && this.$placesAddress == null) {
						localStorage.setItem('placesAddress',JSON.stringify(response.data.userData.placesAddress));
					}
					
					this.isUserFetched = 1;
					this.canUserLogout = response.data.userData.isFromExtMob ? response.data.userData.isFromExtMob :  0;
					
					if (response.data.userData.userName) {
						this.userName = response.data.userData.userName;
						this.corporateLogo = response.data.userData.corporateLogo;
						Vue.prototype.$isUserLoggedIn  = 1; 
						Vue.prototype.$corporateLogo  = response.data.userData.corporateLogo; 
					} else {
						this.userName = '';
						Vue.prototype.$isUserLoggedIn  = ''; 
						Vue.prototype.$corporateLogo  = '';
					}
					
					if (response.data.userData.isTncAgreed == 0) {
						this.$bvModal.show("modal-termsCondition");
					}
					
					this.getMealTypePageAccess();
					this.getCafeMenuByID();
				} else {
					this.isUserFetched = 0;
					// localStorage.clear();
				}
				// this.cartStatus = response.data.cartdetails;
			}).catch((error) => {
				// localStorage.clear();
				console.log(error.response.data.message);
			});
    	},

		getMealTypePageAccess() {	
            this.searching = 1; 	
                	
            var isExtMob = 0;	
            var address = "";	
            var latitude ="";	
            var longitude = "";	
            if ((this.$isExtMob != null && this.$isExtMob == 1) && (this.$placesAddress != null && this.$placesAddress.address != "")) {	
                var isExtMob = this.$isExtMob;	
                var address = this.$placesAddress.address;	
                var latitude = this.$placesAddress.latitude;	
                var longitude = this.$placesAddress.longitude;	
            }	
                	
            this.axios.get('/getMealTypePageAccess',{	
                params: {	
                    isFromMobExt: isExtMob,	
                    address: address,	
                    latitude: latitude,	
                    longitude:longitude	
                }	
            })	
            .then((res) => {	
                let status = res.status;	
                if (status == 200 && res.data.status == 200) {	
                    this.searching = 0; 	
					if(res.data.mealTypeTabs.length !== 0) {	
						localStorage.setItem('mealTypeTabsData', JSON.stringify(res.data));	
						// console.log(localStorage.getItem('mealTypeTabsData'));	
					} else {	
						localStorage.removeItem('mealTypeTabsData');	
					}	
                    var menuRestBranchID = res.data.mealTypeTabs[0].restBranchID;	
					if (menuRestBranchID > 0) {	
						console.log('menueRestBranchID - ' + menuRestBranchID);	
						this.getMenuRestBranchID = menuRestBranchID;	
						
						if (this.loadMealTypePageAccess == 0) {	
							this.getCafeMenuByID();	
							this.loadMealTypePageAccess = 1;	
							this.getBannerByMealType();	
						} 	
					}	
                    if (res.data.token != '') {	
                        var userData = {	
                            "utid":res.data.token,	
                        }	
                        localStorage.setItem('uTid',JSON.stringify(userData));	
                    }	
                    if (res.data.mealTypeTabs.length !== 0) {	
                        localStorage.setItem('mealTypeTabsData',JSON.stringify(res.data));	
                    } else {	
                        localStorage.removeItem('mealTypeTabsData');	
                    }	
                } else {	
                    // console.log(res.data.message);	
                    this.searching = 0; 	
                    console.log(res.data.message);	
                    // this.$router.push('/');	
                }	
            }).catch((error) => {	
                this.searching = 0;	
                console.log(error.response.data.message);	
            });	
        },

		// getCheckOutAccess()
		// {
		// 	if(this.$userData !== null && this.$userData.mobileNo != null && this.$userData.mobileNo != '')
		// 	{
		// 		this.$router.push('/checkOut');
		// 	}
		// 	else
		// 	{
		// 		this.$bvModal.show("modal-mobile");
		// 	}
		// },
	},

	directives: {
		'class-on-scroll': ClassOnScroll,
		"sticky": VueStickyDirective
	},
						
    components: {
				 headerLayout,
				 footerLayout, 
				 carousel,
				//  VueDropdown, 
				 VsaList,
				 VsaItem,
				 VsaHeading,
				 VsaContent,
				 VsaIcon,
				 IntegerPlusminus,
				 Multiselect,
				 searchBoxPopup,
				//  VueHorizontalList,
				 },
}
</script>

<template>
    <div class="container-fuild m-0 p-0">
<!-- <headerLayout :cartDetailsHead="cartDetails" :cartItemsDetailsHead="cartItemsDetails"/> -->
<headerLayout />
<searchBoxPopup :restid="this.restaurantBranchIDForCafe" />
	<!-- header layout -->
        <main>

			<div class="container search_filter onlyMobile">
                <div class="row">
                    <!-- <div class="col-6">
                        <div class="form-group bxSearchBox" v-b-modal.modal-searchBox>
                            <div class="inner-addon right-addon">
                                <i class="bx bx-search bxSearch"></i>
                                <input type="text" v-model="userSearchKey" @input="getSearchSugestions" class="form-control userSearchInput" placeholder="Search Restaurants, Speciality, Dishes"/>
                            </div>
                        </div>
                        <div class="form-group suggestionBoxMain" v-if="sugesstionArr.length > 0">
                            <div class="inner-addon right-addon sugesstionBox">
                                <div class="searchCardDiv" v-for="(searchRes,i) in sugesstionArr" v-bind:key="i">
                                    <div v-if="searchRes.searchCardType== 'Restaurant'">
                                        <router-link :to="{ name: 'restaurantDetailByShort', params: { id: searchRes.restIdentifierString} }">   
                                            <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                            <p class="searchCardTitle">{{searchRes.restName}} - {{searchRes.branchName}}</p>
                                            <span class="searchCardType">{{searchRes.searchCardType}}</span>
                                        </router-link>
                                    </div>

                                    <div v-if="searchRes.searchCardType== 'Speciality'">
                                    <a :href="$router.resolve({ name: 'restaurantCollections', params: { type:'Speciality',id: searchRes.cuisineName } }).href">
                                        <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                        <p class="searchCardTitle">{{searchRes.cuisineName}}</p>
                                        <span class="searchCardType">{{searchRes.searchCardType}}</span>
                                    </a>
                                    </div>

                                    <div v-if="searchRes.searchCardType== 'Dish'">
                                    <a :href="$router.resolve({ name: 'restaurantCollections', params: { type:'Dish',id: searchRes.dishName } }).href">
                                        <img :src="searchRes.image == null || searchRes.image == '' ? 'img' : searchRes.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; height:55px;border-radius:2px;float: left;margin-right: 10px;" alt="thumb" class="lazy">
                                        <p class="searchCardTitle">{{searchRes.dishName}}</p>
                                        <span class="searchCardType">{{searchRes.searchCardType}}</span>
                                    </a>
                                    </div>
                                    <hr class="hrBorder">
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- / col -->
                    <div class="col-9">
                        <multiselect @input="changeCafe" v-model="cafeRestBranchID" :options="cafeteriasForCorp" track-by="restBranchID" label="restBranchName"></multiselect>
						<a href="#0" class="customAnch changeCafe">Change Cafeteria</a>
                    </div>
					<div class="col-3 searchIcon_mob">
						<a class="serachImgDiv" v-b-modal.modal-searchBox>
							<img class="serachImg" src="../../templateAssests/img/ckgallery/search.png" width="30" height="30" alt="">
						</a>
					</div>
                </div>
            </div>

            <div class="container margin_20_20" v-if="bannerShimmer == 0"> 
                <!-- carousle coantainer -->
                <div class="row">
                    <div class="col-md-3" v-for="j in 4" v-bind:key="j">
                        <VShimmerBlock width="100" heightPx="325" />
                    </div>
                </div>
            </div>
            <div class="container margin_20_20" v-if="bannerDetailsArr.length > 0 && bannerShimmer == 1">
                <carousel :autoplay="true" :navText="navIcons" :responsive="{0:{items:2, nav:true, loop:bannerDetailsArr.length > 2 ? true : false},600:{items:3,nav:true, loop:bannerDetailsArr.length > 3 ? true : false},1200:{items:4,nav:true, loop:bannerDetailsArr.length > 4 ? true : false},1800:{items:5,nav:true, loop:bannerDetailsArr.length > 5 ? true : false}}" :dots="false" :margin="5">
                <!-- <carousel :autoplay="true" :navText="navIcons" :responsive="{0:{items:2,nav:true},600:{items:3,nav:true},1200:{items:4,nav:true},1800:{items:5,nav:true}}" :dots="false" :loop="true" :margin="5"> -->
                    <div v-for="bannerDetails in bannerDetailsArr" v-bind:key="bannerDetails.bannerID">
                        <div class="item_version_2">
                            <router-link :to="{ name: 'restaurantCollections', params: { id: bannerDetails.bannerID} }">
                                <figure class="cards">
                                    <!-- <span>OFFER</span> -->
                                    <img :src="bannerDetails.bannerImage == null || bannerDetails.bannerImage == '' ? 'img' : bannerDetails.bannerImage" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="350" height="350">
                                    <!-- <div class="info">
                                        <h3>UPTO 50% OFF</h3>
                                        <small>OFFERS TO BRIGHTEN YOUR DAY</small>
                                    </div> -->
                                </figure>
                            </router-link>
                        </div>
                    </div>
                </carousel>
            </div>

        <!-- </div> -->
        <!-- /container -->
        
            <div class="page_header element_to_stick">
                <div class="container">
                    <div class="row">
						<div class="col-5 col-xl-2 col-lg-2 col-md-2 dietDiv">
							<vue-picker placeholder="Select Diet" v-if="allFoodTypes.length != 0" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
								<vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
									A
								</div> All</vue-picker-option>
								<vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
									<div class="foodType align-center" :style="{background : foodTypes.color}">
										{{foodTypes.shortCode}}
									</div>
									{{foodTypes.foodTypeName}}
								</vue-picker-option>
								<!-- <vue-picker-option value="Halal"><div class="foodType halalFood align-center">
									H
								</div>Halal</vue-picker-option> -->
							</vue-picker>
						</div>
                        <div class="col-7 col-xl-7 col-lg-7 col-md-7 mealTabsDiv">
                            <!-- <h1>Smartworks Food Court 4</h1> -->
							<ul id="primary_nav" v-if="getAllMealTypesTabs && getAllMealTypesTabs.length != 0">
								<li v-for="menuTabAccess in getAllMealTypesTabs" :key="menuTabAccess.mealTypeID" @click="checkCafePage(menuTabAccess.menuTabType, menuTabAccess.restBranchID)"><router-link class="list-group-item list-group-item-action" :class="{ 'active': menuTabAccess.menuTabType === 'cafeteria' }" :to=" menuTabAccess.menuTabType === 'cafeteria' ? '' : { name: 'restaurants', params: { type:menuTabAccess.menuTabType } }">{{menuTabAccess.mealTypeDisplayName}}</router-link></li>
							</ul>
                        </div>
							<div class="col-4 col-xl-3 col-lg-3 col-md-3 d-none d-md-block">
								<div style="float:right;width:100%;">
									<!-- <select style="border: transparent;background: transparent;font-family: 'gothamBook';font-size: 18px;">
										<option>
											<h1>Smartworks Food Court 4</h1>
										</option>
										<option>
											<h1>Codetreasure Food Court</h1>
										</option>
									</select><br>
									<a href="#0" class="customAnch changeCafe">Change Cafeteria</a> -->
                      				<!-- <label class="form-label" for="formrow-endTime-input">Select Cafeteria </label> -->
									 <multiselect @input="changeCafe" v-model="cafeRestBranchID" :options="cafeteriasForCorp" track-by="restBranchID" label="restBranchName"></multiselect>
									 <a href="#0" class="customAnch changeCafe">Change Cafeteria</a>


								</div>
                        </div>
                    </div>
                    <!-- /row -->		       
                </div>
            </div>
            <!-- /page_header -->

            <!-- /secondary_nav -->
			
<div  v-if="list.length > 0">
		<nav class="secondary_nav sticky_horizontal"  v-class-on-scroll="{ class: 'is_stuck', offset: 0 }">
		    <div class="container">
		        <ul id="secondary_nav">
					<scrollactive class="my-nav" active-class="activeMenu" :offset="148">
						<!-- <li><a class="list-group-item list-group-item-action scrollactive-item" href="#section-1">Last Ordered</a></li> -->
						<!-- <li><a class="list-group-item list-group-item-action scrollactive-item" href="#section-2">Starter</a></li> -->
						<li v-for="item in list" v-bind:key="item.categoryid"><a class="list-group-item list-group-item-action scrollactive-item" v-bind:href="'#section'+item.categoryTitle.charAt(0)">{{item.categoryTitle}}</a></li>
						<!-- <li><a class="list-group-item list-group-item-action" href="#section-4">Beverages</a></li> -->
						<!-- <li><a class="list-group-item list-group-item-action" href="#section-5"><i class="icon_chat_alt"></i>Reviews</a></li> -->
		        	</scrollactive>
				</ul>
		    </div>
		    <span></span>
		</nav>

		<!-- /secondary_nav -->

            <div class="bg_gray mainDivCuisineDetails">
		    <div class="container margin_detail">
		        <div class="row">
		            <!-- <div class="col-lg-8 list_menu"> -->
		            <div id="list_menu_div" class="col-lg-8 list_menu" :class="$isExtMob == 1 ? 'mobListExt' : ''">
						<!-- API SECTION -->
						<!-- <VShimmerLine width="20" /> -->
						<div v-if="Shimmer == 0">
							<section>
							<div class="row">
								<div class="col-md-9 col-lg-9 col-xl-9 sectionText" style="width:50%!important;margin-bottom:10px;">
		                    		<h4><VShimmerLine width="40" /></h4>
								</div>
							</div>
		                    <div class="row">
		                        <div class="col-md-6" v-for="n in 10" v-bind:key="n">
								  <div class="strip">
		                            <a class="menu_item modal_dialog">
										<!-- <div class="discountTag" v-if="itemData.discountprice > 0">
											<img src="../../templateAssests/img/ckgallery/discount_tag.png" alt="thumb" height="20" width="65">
											<span class="discountText">{{itemData.discountprice}}% off</span>
										</div> -->
										<!-- <span class="ribbon1 off">10% off</span> -->
		                                <figure><VShimmerBlock width="98" heightPx="75" /></figure>
										<VShimmerLine width="16" heightPx="24" style="position: absolute;bottom:43px;right:20px;" />
		                                <h3><VShimmerLine width="40" /></h3>
		                                <p style="margin-top:15px;" class="two-line-text"><VShimmerLine width="80" /></p>
		                                <p style="margin-bottom:15px;" class="two-line-text"><VShimmerLine width="80" /></p>
										<VShimmerLine width="20" />
										<br>
		                               <VShimmerLine width="8" />
		                            </a>
								  </div>
		                        </div>
		                        
		                    </div>
		                    <!-- /row -->
		                </section>
		                <!-- /section -->
						</div>
						<!-- <div class="align-right" v-if="list.length <= 0">
							<vue-picker placeholder="Select Diet" v-if="allFoodTypes.length !== 0" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
											<vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
												A
											</div> All</vue-picker-option>
											<vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
												<div class="foodType align-center" :style="{background : foodTypes.color}">
													{{foodTypes.shortCode}}
												</div>
												{{foodTypes.foodTypeName}}
											</vue-picker-option>
							</vue-picker>
						</div> -->
		               <section v-bind:id="'section'+item.categoryTitle.charAt(0)" v-for="(item, typeIndex) in list" v-bind:key="item.categoryid">
							<div class="row">
								<!-- <div class="col-md-9 col-lg-9 col-xl-9 sectionText" :class="{'col-sm-12 col-xs-12': typeIndex > 0}" :style="typeIndex == 0 ? 'width:50%!important;' : ''"> -->
								<div class="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12 sectionText">
		                    		<h4>{{item.categoryTitle}}</h4>
								</div>
								<!-- <div class="col-md-3 col-lg-3 col-xl-3 align-right dishTypes" style="width:50%!important;" v-if="typeIndex == 0">
									<vue-picker placeholder="Select Diet" v-if="allFoodTypes.length !== 0" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
											<vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
												A
											</div> All</vue-picker-option>
											<vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
												<div class="foodType align-center" :style="{background : foodTypes.color}">
													{{foodTypes.shortCode}}
												</div>
												{{foodTypes.foodTypeName}}
											</vue-picker-option> -->
											<!-- <vue-picker-option value="Halal"><div class="foodType halalFood align-center">
												H
											</div>Halal</vue-picker-option> -->
										<!-- </vue-picker>
								</div> -->
							</div>
		                    <div class="row" v-if="Shimmer == 1">
		                        <div class="col-md-6" v-for="(itemData, cusineIndex) in item.data" v-bind:key="itemData.id" v-on:click="getCusineDetails(itemData, typeIndex, cusineIndex)">
								  <div class="strip strip_mobile">
		                            <a class="menu_item modal_dialog mobile_item" v-b-modal.modal-viewDish>
										<div class="discountTag" v-if="itemData.discountprice > 0">
											<img src="../../templateAssests/img/ckgallery/discount_tag.png" alt="thumb" height="20" width="65">
											<span class="discountText">{{itemData.discountprice}}% off</span>
										</div>
										<!-- <span class="ribbon1 off">10% off</span> -->
		                                <figure :style="itemData.thumbnail == null || itemData.thumbnail == '' ? 'display:none;' : 'display:block;'"><img :src="itemData.thumbnail == null || itemData.thumbnail == ''? 'img' : itemData.thumbnail" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" alt="thumb" width="90" class="cardImage"></figure>
										<span  v-if="itemData.isInStock == 0" class="outOfStockSpan outOfStockSpan_mobile">OUT OF STOCK</span>
										<div class="addBtnPostion addBtnPostion_mobile" v-else>
											<span v-if="itemData.isCustomisable=='0' && itemData.quantity == 0 && itemData.isInStock != 0" v-on:click="addItemToCart(itemData, typeIndex, cusineIndex, 0)" class="btn_1 addBtn_mobile addBtn mb_5">+ADD</span>
											<integer-plusminus v-else-if="itemData.quantity > 0" v-on:click.native="updateItemToCart(itemData, typeIndex, cusineIndex, 0)" v-model="itemData.quantity" :min="itemData.mealType == 2 ? itemData.minorderquantity : 0" :max="100" :step="1">
											</integer-plusminus>
											<div v-if="itemData.isCustomisable=='1'">
												<span  v-if=" itemData.quantity == 0"  class="btn_1 addBtn_mobile addBtn mb_5">+ADD</span>
												<div class="customisableSpan align-center">Customisable</div>
											</div>
										</div>
										<!-- if isCustomisable show addon modal -->
		                                <h3>{{itemData.name}}</h3>
		                                <div class="dishPrice_mobile">₹{{itemData.price}} |</div>
		                                <p class="two-line-text desc" v-if="itemData.description !='' || itemData.description != null">{{itemData.description}}</p>
										<div class="dishServings">Serves {{itemData.serves}} </div>

										<!-- {{itemData.type[0].name.charAt(0)}} -->
											<div class="foodType align-center" :style="{background : mealType.background}" v-for="mealType in itemData.type" v-bind:key="mealType.name" :id="mealType.name"  v-b-tooltip.hover="mealType.name" :title="mealType.name">
												{{mealType.shortCode}}
											</div>
		                                <p class="one-line-text desc_mobile" v-if="itemData.description !='' || itemData.description != null">{{itemData.description}}</p>
											<!-- <div class="foodType veganFood align-center">
												VE
											</div>
											<div class="foodType vegFood align-center">
												V
											</div>
											<div class="foodType halalFood align-center">
												H
											</div> -->
		                                <div class="dishPrice">₹{{itemData.price}}</div>
		                            </a>
								  </div>
		                        </div>
		                        
		                    </div>
		                    <!-- /row -->
		                </section>
		                <!-- /section -->
		            </div>
		            <!-- /col -->
		            <div class="col-lg-4" id="sidebar_fixed" data-v-sticky-container>
		            <!-- <div class="col-lg-4 sidebar_fixed_mobile" id="sidebar_fixed" data-v-sticky-container> -->
						<div v-sticky="options">
						<div class="box_order mobile_fixed" v-if="!cartDetails.length">
		                    
		                    <div class="main mainCart">
								<div class="cartItems align-center">
									<img src="../../templateAssests/img/ckgallery/emptybag.png">
									<h4>No Item in Cart</h4>
								</div>
								
								
		                    </div>
		                </div>
		                <!-- /box_order -->
		                <div class="box_order mobile_fixed" v-else>
		                    <div class="head">
		                       <div class="row">
								   <!-- <div class="col-md-2 col-lg-2 col-xl-2"> -->
								   <div>
										<img :src="cartDetails[0].restImage == null || cartDetails[0].restImage == '' ? 'img' : cartDetails[0].restImage" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; max-height:55px;object-fit:cover;float: left;margin-right: 10px;" alt="thumb" class="lazy">
									<!-- </div> -->
									<!-- / col -->
									<!-- <div class="col-md-10 col-lg-10 col-xl-10"> -->
										<!-- <div  class="restaurantsDetails"> -->
											<h5 v-if="cartDetails != undefined && cartDetails[0].length !== 0">{{cartDetails[0].restName}}</h5>
											<h5 v-else>Restaurant</h5>
										<!-- </div> -->
										<!-- <div  class="locationDetails"> -->
											<p v-if="cartDetails != undefined && cartDetails[0].length !== 0">{{cartDetails[0].restaurantBranchName}}</p>
										<!-- </div> -->
									</div>
									<!-- /col -->
							   </div>
		                    </div>
		                    <!-- /head -->
		                    <div class="main mainCart">
								<div class="cartItems">
									<h4>Cart Items 
										<small v-if="cartDetails != undefined && cartDetails[0].length !== 0">({{cartDetails[0].cardcount}})</small>
										<small v-else>(0)</small>
									</h4>
								</div>
								<div v-if="cartItemsDetails != undefined" class="cartTotalItemDetails">
									<div class="row cartRow" v-if="ItemsInCart.quantity > 0" v-for="ItemsInCart in cartItemsDetails" v-bind:key="ItemsInCart.cartID" style="margin-bottom:10px;">
										<div class="col-md-8 col-lg-8 col-xl-8 dishName">
											<!-- <div class="cartItemNameDiv"><span class="cartItemName">{{ItemsInCart.cuisineName}}</span> <div class="foodTypeContent align-center" :style="{background : foodType.background}" v-for="foodType in ItemsInCart.type" v-bind:key="foodType.name"  :id="foodType.name"  v-b-tooltip.hover="foodType.name" :title="foodType.name"></div></div> -->
											<h5>{{ItemsInCart.cuisineName}} <div class="foodTypeContent align-center" :style="{background : foodType.background}" v-for="foodType in ItemsInCart.type" v-bind:key="foodType.name"  :id="foodType.name"  v-b-tooltip.hover="foodType.name" :title="foodType.name"></div></h5>
											<!-- <a v-if="ItemsInCart.isCustomisable == 1" class="customAnch customizeAddons"><p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom:0px;font-size:12px;color:#F3766A;">Customize add on</p></a> -->
											<a v-if="ItemsInCart.isCustomisable == 1" @click="addOnsModal(ItemsInCart.cartID, ItemsInCart.cuisineID, ItemsInCart.restBranchID)" class="customAnch customizeAddons"><p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom:0px;font-size:12px;color:#F3766A;">Customize add on</p></a>
										</div>
										<!-- / col -->
										<div class="col-md-2 col-lg-2 col-xl-2 dishDetails align-right">
											<!-- <input style="border-radius:18px;" type="number" class="form-control" value="1"/> -->
											<integer-plusminus  v-on:click.native="updateItemToCart(ItemsInCart, 0, 0, 1)"  v-model="ItemsInCart.quantity" :min="0" :max="100" :step="1">
												<!-- {{ItemsInCart.quantity}} -->
											</integer-plusminus>
										</div>
										<!-- / col -->
										<div class="col-md-2 col-lg-2 col-xl-2 dishDetails dishCartPrice align-right">
											<div class="dishRate">₹{{ItemsInCart.itemTotal}}</div>
										</div>
										<!-- / col -->
										<!-- <hr class="hrBorder"> -->

										<!-- <div class="col-md-8 col-lg-8 col-xl-8 dishName">
											<h5>Kung Pizza</h5>
											<div class="dishServings">Serve 1 </div>
												<div class="foodType veganFood align-center">
													VE
												</div>
												<div class="foodType vegFood align-center">
													V
												</div><br>
											<a href="#" class="customAnch customizeAddons"><p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom:0px;font-size:12px;color:#F3766A;">Customize add on:Burnt chilli, sauce and other.</p></a>
										</div> -->
										<!-- / col -->
										<!-- <div class="col-md-4 col-lg-4 col-xl-4 dishDetails align-right"> -->
											<!-- <input style="border-radius:18px;" type="number" class="form-control" value="1"/> -->
											<!-- <integer-plusminus :min="1" :max="100" :step="1">
												
											</integer-plusminus>
											<span class="dishRate"><strong>₹380</strong></span>
										</div> -->
										<!-- / col -->
										<!-- <hr class="hrBorder"> -->
									</div>
									<!-- /row  -->
								</div>
								<!-- endIf cartItems -->
		                        <ul class="clearfix cartPrice" v-if="cartDetails[0].totalCorporateSubsidy > 0">
		                            <li>Corporate subsidy<span>- ₹{{cartDetails[0].totalCorporateSubsidy}}</span></li>
		                            <!-- <li>Item Total<span>₹{{cartDetails[0].totalitemprice}}</span></li> -->
		                            <!-- <li><u id="tax">Tax</u><sup style="color:#f3766a;">*</sup><span>₹{{cartDetails[0].tax_amount}}</span></li>
		                            <li><u id="otherCharges">Other Charges</u><sup style="color:#f3766a;">*</sup><span>₹{{cartDetails[0].totalOcharge}}</span></li> -->
		                        </ul>

								<!-- / Tooltips for charges -->
								 <!-- <b-tooltip target="tax" triggers="hover" placement="right">
									<div class="row">
										<div class="col-8 align-left mb_5">
											<span>CGST</span>
										</div>
										<div class="col-4 align-right">
											<span>₹{{cartDetails[0].cgst}}</span>
										</div>
										<div class="col-8 align-left">
											<span>SGST</span>
										</div>
										<div class="col-4 align-right">
											<span>₹{{cartDetails[0].sgst}}</span>
										</div>
									</div>
								</b-tooltip>
								<b-tooltip target="otherCharges" triggers="hover"  placement="right">
									<div class="row">
										<div class="col-8 align-left mb_5">
											<span>Delivery Charges</span>
										</div>
										<div class="col-4 align-right">
											<span>₹{{cartDetails[0].totaldcharge}}</span>
										</div>
										<div class="col-9 align-left">
											<span>Restaurants Charges</span>
										</div>
										<div class="col-3 align-right">
											<span>₹{{cartDetails[0].totalrcharge}}</span>
										</div>
									</div>
								</b-tooltip> -->
								<!-- / Tooltips for charges -->

		                        <div class="row opt_order">
									<ul class="totalPrice">
		                            <li class="total">Total<span>₹{{cartDetails[0].totalitemprice}}</span></li>
									<span class="extraCharges">Extra charges may apply</span>
									</ul>
									
		                        </div>
		                        
		                        <div class="btn_1_mobile">
		                            <!-- <a @click="getCheckOutAccess" class="btn_1 full-width mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;">Checkout</a> -->
		                            <a href="/checkOut" class="btn_1 full-width mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;">Checkout</a>
		                        </div>
		                    </div>
		                </div>
		                <!-- /box_order -->
		                <!-- <div class="btn_reserve_fixed onlyMobile mobileBasketDiv" :style="cartDetails.length <= 0 ? 'display:none!important;' : 'display:block;'"> -->
		                <div id="mobile_basket_div" class="btn_reserve_fixed onlyMobile mobileBasketDiv" :class="$isExtMob == 1 ? 'mobBasketExt' : ''" :style="cartDetails.length <= 0 ? 'display:none!important;' : 'display:block;'">
							<div class="row">
								<div class="col-8 cartItemsCharges" v-if="cartDetails.length > 0">
									<p class="cartItemsMob">{{cartItemsDetails.length}} item in a cart | ₹
										{{cartDetails[0].totalitemprice}}
										</p>
									<p class="cartItemsCharges">Extra Charges may apply</p>
								</div>
								<div class="col-4 viewCartItems">
									<a href="/checkOut"><button class="btn">View Cart</button></a>
								</div>
							</div>
						</div>
		            </div>
					</div>
		        </div>
		        <!-- /row -->
		    </div>
		    <!-- /container -->
		</div>
		<!-- /bg_gray -->

		<div class="container fssaiSection align-center" v-if="licenseNO != ''">
			<div class="fssaiLogo">
				<img src="../../templateAssests/img/ckgallery/fssai.png" height="35" width="70" alt="fssai">
			</div>
			<div class="fssaiLicNo">
				<span>License No. {{licenseNO}}</span>
			</div>
		</div>
		<!-- /container -->
</div>
<!-- /  if corpBranchId is empty -->
<div v-if="list.length <= 0">
	<div class="container">
		<div class="noResultsMainDiv align-center row">
			<div class="col-12 align-right" style="margin: auto;width: 50%;padding: 10px;">
				<h1 style="font-size:25px;">Sorry, no results found</h1>
				<span style="color:#777;font-size:14px;">Your area is currently not serviceable</span>
			</div>
			<!-- <div class="col-6 align-left">
				<img src="../../templateAssests/img/ckgallery/no_results.png" width="150">
			</div> -->
		</div>
	</div>
</div>
<!-- /list no results end -->

	</main>
	<!-- /main -->

  <!-- Modal item order -->
  <b-modal id="modal-viewDish" scrollable  :footer-bg-variant="footerBgVariant" v-if="cusineDetailsDataArr && cusineModalShow === true"  size="md" centered>
	<div class="container" style="max-width:100%!important;padding:0px;">
		
		<div>
				<img :style="cusineDetailsDataArr.image == null || cusineDetailsDataArr.image == '' ? 'display:none;' : 'display:block;'" :src="cusineDetailsDataArr.image == null || cusineDetailsDataArr.image == ''? 'img' : cusineDetailsDataArr.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="150" style="width:150px; max-height:200px; padding: 0px 15px 10px 0;float: left;margin-right: 10px;" alt="thumb" class="lazy cuisineModalImg">
				<!-- <div  class="menuDetails"> -->
					<h5>{{cusineDetailsDataArr.name}}</h5>
					<p style="color:#F68F30;margin-bottom:5px;">{{cusineDetailsDataArr.restaurantName}}</p>
                    <p class="menuPrice">₹{{cusineDetailsDataArr.price}} | Serves {{cusineDetailsDataArr.serves}}</p>
					<div class="foodTypeModal align-center" :style="{background : mealType.background}" v-for="mealType in cusineDetailsDataArr.type" v-bind:key="mealType.name">
						{{mealType.name}}
						<!-- {{mealType.name.replace(/,/g, "")}} -->
					</div>
					<!-- <div class="foodTypeModal halalFood align-center">
						Halal
					</div>
					<div class="foodTypeModal veganFood align-center">
						Vegan
					</div>
					<div class="foodTypeModal vegFood align-center">
						Vegetarian
					</div> -->
					<p style="margin-bottom:5px;">{{cusineDetailsDataArr.description}}</p>
				<!-- </div> -->
		</div>
		<!-- <div class="row">
			<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="margin-right:20px;">
				<img :src="cusineDetailsDataArr.image" width="150" height="150" alt="thumb" class="lazy cuisineModalImg">
			</div>
			<div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
				<div  class="menuDetails">
					<h5>{{cusineDetailsDataArr.name}}</h5>
					<p style="color:#F68F30;">{{cusineDetailsDataArr.restaurantName}}</p>
                    <p class="menuPrice">₹{{cusineDetailsDataArr.price}} | Serves {{cusineDetailsDataArr.serves}}</p>
					<div class="foodTypeModal align-center" :style="{background : mealType.background}" v-for="mealType in cusineDetailsDataArr.type" v-bind:key="mealType.name">
						{{mealType.name}} -->
						<!-- {{mealType.name.replace(/,/g, "")}} -->
					<!-- </div> -->
					<!-- <div class="foodTypeModal halalFood align-center">
						Halal
					</div>
					<div class="foodTypeModal veganFood align-center">
						Vegan
					</div>
					<div class="foodTypeModal vegFood align-center">
						Vegetarian
					</div> -->
					<!-- <p>{{cusineDetailsDataArr.description}}</p>
				</div>
			</div>
		</div> -->
		<!-- /Header row -->
		<br>
		<!-- <div> -->
			<div class="row">
				<div class="customizeSection col-6 col-lg-6 col-xl-6" style="float:left;">
					<p v-if="cusineDetailsDataArr.isCustomisable=='1'" style="color: #a38379;margin-bottom:0px; padding-top: 8px;">Customize</p>
				</div>
				<!-- <div class="socialBtns align-right col-6 col-lg-6 col-xl-6">
					<div class="socialBtnsDiv align-center" style="background:#4C6EF5;">
						<img src="../../templateAssests/img/ckgallery/fb.png" height="15" width="10" alt="fb">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#00E676;">
						<img src="../../templateAssests/img/ckgallery/whatsapp.png" height="15" width="14" alt="whatsapp">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#A4827D;">
						<img src="../../templateAssests/img/ckgallery/share.png" height="15" width="15" alt="share">
					</div>
				</div> -->
			</div>
			<hr v-if="cusineDetailsDataArr.isCustomisable=='1'" style="opacity:1;margin: 8px 0 12px 0;">
		<!-- / customizeSection -->

		<!--  Dropdown -->
		<vsa-list class="sauceSection" v-if="cuisineItemDetails != undefined && cusineDetailsDataArr.isCustomisable=='1'">
			<div  v-for="(addonItemsArr, groupIndex) in cuisineItemDetails" v-bind:key="addonItemsArr.addonGroupID">
    		 <vsa-item :forceActive="groupIndex == 0 ? true : false">
				<vsa-heading>
					{{addonItemsArr.groupName}} 
					<span v-if="addonItemsArr.required == 0">(Optional)</span>
					<span v-if="addonItemsArr.required == 1">(Required)</span>
				</vsa-heading>
	
				<vsa-content>
					<div class="row" v-for="addOnItems in addonItemsArr.data" v-bind:key="addOnItems.addOnItemID">
						<!-- <div class="col-md-1">
							<div class="foodType modalExpandType vegFood align-center">
								V
							</div>
						</div> -->
						<div class="col-md-12">
							<!-- <b-form-checkbox
						value="accepted"
						unchecked-value="not_accepted"
						class="mb-3"
						checked
						plain
						>{{addOnItems.title}} (+ ₹{{addOnItems.price}})</b-form-checkbox
					> -->
					 <b-form-group v-slot="{ ariaDescribedby }">
						<b-form-checkbox-group
							id="checkbox-group-2"
							v-model="selectedAddOnItems"
							:aria-describedby="ariaDescribedby"
							name="addOnItems" @change="selectAddons(cusineDetailsDataArr.price)">
							<b-form-checkbox checked="true" :value="addOnItems">{{addOnItems.title}} (+ ₹{{addOnItems.price}})</b-form-checkbox>
						</b-form-checkbox-group>
					 </b-form-group>
						</div>
					</div>
				</vsa-content>
			</vsa-item>
			</div>
  </vsa-list>
		<!-- / Dropdown -->
		<!-- </div> -->
		<!-- endiIF -->
	</div>
		<!-- /Container -->
		<template #modal-footer>
        <div class="w-100">
          <!-- <p class="float-left">Item total ₹{{cusineDetailsDataArr.price}}</p> -->
          <p class="float-left">Item total ₹{{itemTotalPopup}}</p>
		  <div class="row float-right" v-if="cusineDetailsDataArr.isInStock != 0">
			  <div class="col-6 align-right">
				  <!-- {{cusineDetailsDataArr}} -->
				 <!-- <integer-plusminus v-on:click.native="updateItemToCart(itemData, typeIndex, cusineIndex, 2)" v-model="itemFromModal" :min="0" :max="100" :step="1">
				 </integer-plusminus> -->
				 <integer-plusminus v-on:click.native="updateItemToCartModal(cusineDetailsDataArr,itemData, typeIndex, cusineIndex, 2)" v-model="itemFromModal" :min="cusineDetailsDataArr.mealType == 2 ? cusineDetailsDataArr.minorderquantity : 0" :max="100" :step="1">
				 </integer-plusminus>
					<!-- <integer-plusminus v-if="cusineDetailsDataArr.quantity > 0" v-on:click.native="updateItemToCart(itemData, typeIndex, cusineIndex, 2)"  v-model="itemFromModal" :min="0" :max="100" :step="1">
					</integer-plusminus> -->
				</div>
				<div class="col-6">
				<!-- <b-button
					v-if="cusineDetailsDataArr.isCustomisable=='1'"
					size="sm"
					class="float-right viewDish-footer-btn"
					 v-on:click="checkAddonReq(cuisineItemDetails,cusineDetailsDataArr, cusineTypeIndex, cusineItemIndex, 1)"
				>
					Add to Cart1
				</b-button> -->
				<b-button
					size="sm"
					class="float-right viewDish-footer-btn"
					 v-on:click="addItemToCart(cusineDetailsDataArr, cusineTypeIndex, cusineItemIndex, 1)"
				>
					Add to Cart
				</b-button>
				</div>
		  </div>
        </div>
      </template>
  </b-modal> 
    <!-- /Modal item order -->

	<b-modal id="modal-cuisinePopup" scrollable  :footer-bg-variant="footerBgVariant" v-if="cusinieAddonsDetails && cusineAddonModalShow === true" hide-header  size="md" centered>
		<div class="container" style="max-width:100%!important;padding:0px;">
		
		<div>
            <img :style="cusinieAddonsDetails.cuisineDetail.image == null || cusinieAddonsDetails.cuisineDetail.image == '' ? 'display:none;' : 'display:block;'" :src="cusinieAddonsDetails.cuisineDetail.image == null || cusinieAddonsDetails.cuisineDetail.image == ''? 'img' : cusinieAddonsDetails.cuisineDetail.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="150" style="width:150px; max-height:200px; padding: 0px 15px 10px 0;float: left;margin-right: 10px;" alt="thumb" class="lazy cuisineModalImg">
            <h5>{{cusinieAddonsDetails.cuisineDetail.name}}</h5>
            <p style="color:#F68F30;margin-bottom:5px;">{{cusinieAddonsDetails.cuisineDetail.restaurantName}}</p>
            <p class="menuPrice">₹{{cusinieAddonsDetails.cuisineDetail.price}} | Serves {{cusinieAddonsDetails.cuisineDetail.serves}}</p>
            <div class="foodTypeModal align-center" :style="{background : mealType.background}" v-for="mealType in cusinieAddonsDetails.cuisineDetail.type" v-bind:key="mealType.name">
                {{mealType.name}}
            </div>
            <p style="margin-bottom:5px;">{{cusinieAddonsDetails.cuisineDetail.description}}</p>
			
		</div>
		
		<br>
			<div class="row">
				<div class="customizeSection col-6 col-lg-6 col-xl-6" style="float:left;">
					<p v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'" style="color: #a38379;margin-bottom:0px; padding-top: 8px;">Customize</p>
				</div>
				<!-- <div class="socialBtns align-right col-6 col-lg-6 col-xl-6">
					<div class="socialBtnsDiv align-center" style="background:#4C6EF5;">
						<img src="../../templateAssests/img/ckgallery/fb.png" height="15" width="10" alt="fb">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#00E676;">
						<img src="../../templateAssests/img/ckgallery/whatsapp.png" height="15" width="14" alt="whatsapp">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#A4827D;">
						<img src="../../templateAssests/img/ckgallery/share.png" height="15" width="15" alt="share">
					</div>
				</div> -->
			</div>
			<hr v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'" style="opacity:1;margin: 8px 0 12px 0;">
		<!-- / customizeSection -->

		<!--  Dropdown -->
		<vsa-list class="sauceSection" v-if="cusinieAddonsDetails.cuisineAddons != undefined && cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'">
			<div  v-for="(addonItemsArr, groupIndex) in cusinieAddonsDetails.cuisineAddons" v-bind:key="addonItemsArr.addonGroupID">
    		 <vsa-item :forceActive="groupIndex == 0 ? true : false">
				<vsa-heading>
					{{addonItemsArr.groupName}} <span v-if="addonItemsArr.required == 0">(Optional)</span>
				</vsa-heading>
	
				<vsa-content>
					<div class="row" v-for="addOnItems in addonItemsArr.data" v-bind:key="addOnItems.addOnItemID">
						
						<div class="col-md-12">
					 <b-form-group v-slot="{ ariaDescribedby }">
						<b-form-checkbox-group
							id="checkbox-group-2"
							v-model="selectedAddonCartItems"
							:aria-describedby="ariaDescribedby"
							name="addOnItems" @change="selectCartModalAddons(cusinieAddonsDetails.cuisineDetail.price)">
							<b-form-checkbox checked="true" :value="addOnItems">{{addOnItems.title}} (+ ₹{{addOnItems.price}})</b-form-checkbox>
						</b-form-checkbox-group>
					 </b-form-group>
						</div>
					</div>
				</vsa-content>
			</vsa-item>
			</div>
  </vsa-list>
		<!-- / Dropdown -->
		<!-- endiIF -->
	</div>
		<!-- /Container -->
		<template #modal-footer>
        <div class="w-100">
          <p class="float-left">Item total ₹{{itemTotalCartModal}}</p>
		  <div class="row float-right" v-if="cusinieAddonsDetails.isInStock != 0">
			  <div class="col-6 align-right">
				 <integer-plusminus v-on:click.native="updateCartDataFromModal(cusinieAddonsDetails.cuisineDetail)" v-model="itemFromCartModal" :min="0" :max="100" :step="1">
				 <!-- <integer-plusminus v-model="itemFromCartModal" :min="0" :max="100" :step="1"> -->
				 </integer-plusminus>
				</div>
				<div class="col-6">
				<b-button
					size="sm"
					class="float-right viewDish-footer-btn"
					v-on:click="updateItemAddonModal(cusinieAddonsDetails.cuisineDetail, 1)"
				>
					Update Cart
				</b-button>
				</div>
		  </div> 
        </div>
      </template>
  </b-modal> 

<footerLayout />
	<!-- footer layout -->
    </div>
	<!-- /root div -->
</template>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/listing.css"></style>
    <style scoped src="../../templateAssests/css/detail-page.css"></style>
<style scoped>
main{
    background-color:#fff;
}
    .cards{
            max-width:350px;
            max-height:350px;
            border-radius:5px;
        }
        .cards span{
           border-radius: 0;
           right: 0;
           left: 15px;
           font-size: 12px;
           height: auto;
           width: 29%;
        }
        .searchCafes{
            padding-top: 12px;
        }
        .cateresCards{
            max-width:350px;
            max-height:370px;
        }
        .cateresCards img{
            border-radius:5px;
        }
        .cateresTitle{
            font-size: 18px;
            margin-bottom: 5px;
            margin-top:10px;
        }
        .cateresMenuType{
            margin-bottom: 5px;   
        }
        .CateresScore strong{
            padding: 0 0 0 0;
        }
        .restaurantsList{
            margin-top:40px;
        }
        .ribbon1{
                color: #fff;
                display: inline-block;
                position: absolute;
                bottom: 15px;
                left: 71px;
                font-weight: 500;
                border-radius: 3px;
                padding: 6px 8px 4px 8px;
                line-height: 1;
                font-size: 12px;
                font-size: 0.75rem;
                z-index: 9;
        }
        .ribbon1.off {
            background-color: #f3723b;
        }
        .secondary_nav {
            width: 100%;
            background-color: #fff;
            border-top: 1px solid #ededed;
            padding: 20px 0;
            position: relative;
        }
        .secondary_nav span {
            position: absolute;
            height: 7px;
            width: 100%;
            left: 0;
            bottom: 0;
            -webkit-box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
            -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
        }
		.secondary_nav ul li a
		{
			border-radius: 0px;
			background: transparent;
			padding: 5px 8px;
		}
		.list-group-item
		{
			border: 0px;
		}
		.activeMenu
		{
			border:0px;
			border-bottom: 3px solid #A4827D;
			font-size: 15px;
			color: #A4827D!important;
			font-family: 'GothamMedium'!important;
		}
		.secondary_nav ul li a.active, .secondary_nav ul li a:hover
		{
			background: transparent;
			color: #A4827D;
		}
        .bx{
            font-size: 24px;
			color:#212121;
        }
        .filter{
            display: block;
            width: 40px;
            height: 40px;
            line-height: 36px;
            border: 2px solid #f6f6f6;
            color: #74788d;
            border-radius: 4px;
            font-size: 24px;
            text-align:center;
        }
		.fssaiLogo{
		text-align: center;
		padding: 25px 25px 31px;
	}
	.fssaiLicNo{
		font-size: 16px;
		color:#777;
	}
        
		.vsa-list {
			--vsa-heading-padding: 0 0;
			--vsa-highlight-color: transparent; 
			--vsa-bg-color: transparent;
			--vsa-border-color: #eaeaea;
			--vsa-default-icon-size: 0.3;
		}
		.vsa-item__trigger{
			padding: 10px!important;
		}
		/* .vsa-item__trigger__content{
			font-size:14px;
		} */
		.modal-footer{
			background: #fff!important;
		}
		.viewDish-footer-btn{
			background-color: #ffc60c!important;
			color: #212121;
    		/* font-weight: 600; */
			border: 1px solid #ffc60c;
			height: 32px;
			width:100px;
		}
		.owl-theme .owl-nav{
			border-radius:50%!important;
		}
		/* .activeMenu{
			color: #A4827D!important;
			background: #FFF!important;
			border:1px solid #A4827D;
		} */
		.modalExpandType{
			border-radius: 12px;
			width: 20px;
			height: 20px;
			color:#fff;
			display: inline-block;
			padding-top:2px!important;
			font-size: 11px;
		}
		.vue-picker{
			display: block;
			width: 100%;
			font-size: 0.9rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fff;
			border-radius: 5px;
		}
		.vue-picker__dropdown{
			border:1px solid #fff!important;
		}
		.vue-picker__opener:focus{
			box-shadow: none!important;
			border:transparent!important;
		}
		/* .dishTypes{
			width: 20%;
    		margin-left: 0px;
		} */
		/* .item_version_2{
			min-height: 180px;
			min-width: 180px;
		} */
		.owl-item{
			min-height: 180px;
			min-width: 180px;
		}
		.strip figure img{
				z-index:0!important;
			}
		.secondary_nav.is_stuck{
			z-index: 1;
		}
		.addBtnPostion{
			    position: absolute;
				bottom: 20px;
				right: 20px;
		}
    .int-pm {
		 margin-left: 40px;
	}
	ul#primary_nav {
		margin-bottom: 0px;
		padding-left: 7px;
		overflow: scroll;
		white-space: nowrap;
	}
	ul#primary_nav::-webkit-scrollbar 
	{
		display: none;
	}
	ul#primary_nav li{
			display: inline-block;
    		margin-right: 10px;
			border-radius: 30px;
		}
		ul#primary_nav li a{
			background: #E6E6E6;
			padding: 0px 12px;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
		}
		ul#primary_nav li a.active{
			background: #F3766A;
			color: #fff!important;
			border:none!important;
		}
		.customisableSpan{
			/* position: absolute;
			right: 35px;
			bottom: 5px; */
			font-size: 10px;
			color:#A4827D;
			width: 80px;
		}
		.cardImage{
			object-fit: cover;
		}
		.cuisineModalImg{
			object-fit: contain;
		}
		.disabledBtn{
			background: #ced4da!important;
		}
		.disabledBtn:hover{
			background: #ced4da!important;
			color:#000;
		}
		.total{
			margin-left: 15px;
			margin-right: 15px;
			margin-bottom: 0px!important;
		}
		.totalPrice
		{
			margin-bottom: 6px;
		}
		.extraCharges{
			margin-left: 15px;
			font-size: 12px;
		}
		@media (max-width: 991px){
			.strip figure img{
				z-index:0!important;
			}
			.dishTypes{
				width: 100%!important;
				margin-left:0px!important;
			}
		}
		.list_menu section{
			padding-top: 0px!important;
		}
		.menuPrice{
			margin-bottom:5px;
		}
		.outOfStockSpan
		{
			position: absolute;
			right: 24px;
			bottom: 24px;
			font-size: 12px;
			color: red;
			font-size: 12px;
		}
		.noResultsMainDiv
		{
			position: relative;
			max-width: 110rem;
			max-height: initial;
			padding: 80px 0;
			/* margin: 0px auto; */
			/* margin: 3rem auto 5rem; */
		}
		.noResultscontentDiv
		{
			margin: 3rem auto 5rem;
		}
		.cartRow
		{
			margin-left:0px;
			margin-right:0px;
		}
		.dishName, .dishDetails
		{
			padding: 0;
		}
		.dishCartPrice
		{
			padding: 0 5px;
		}
		.searchIcon_mob
		{
			text-align: right;
			margin-top: 10px;
			padding-right: 1px;
		}
</style>

<!-- style for all pages -->
<style>
.swal2-confirm
{
	width: 124px!important;
}
.vue-picker__opener{
			border:transparent!important;
		}
	.custom-checkbox .custom-control-input:checked {
		background-color: #F3766A;
		border-color: #F3766A;
	}
	.custom-checkbox .custom-control-input{
		margin-right: 7.6px;
	}
	.vsa-item__trigger__content{
		font-size:14px;
		color:#777;
	}
	.modal-body{
		padding: 30px 25px 10px 25px;
	}
	#modal-viewDish___BV_modal_header_
	{
		border-bottom:transparent;
	}
	.multiselect__tags{
		padding-left: 0px!important;
		border: none!important;
		background: transparent!important;
	}
	.multiselect__input, .multiselect__single{
		background: transparent!important;
		font-size: 17px!important;
	}
	.multiselect__select:before
	{
		border-color: #F3766A transparent transparent!important;
	}
	.multiselect
	{
		cursor: pointer;
	}
	.bxSearch{
        position: absolute;
        padding: 10px;
        pointer-events: none;
    }
.right-addon .bxSearch { left: 8px;font-size:22px;}
.right-addon input { padding-left: 45px; border-radius: 4px;}

.search_filter
{
    padding: 10px;
}
.sugesstionBox
{
    /* max-height: 20rem; */
    border-radius: 4px;
    /* position: absolute; */
    overflow: auto;
    /* top: 6rem; */
    visibility: visible;
    opacity: 1;
    border: 1px solid #f0f0f0;
    /* box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px; */
    transition: opacity 0.25s ease 0s, top 0.25s ease 0s;
    padding: 15px;
}
/* .cartTotalItemDetails::-webkit-scrollbar 
{
    display: none;
} */
.sugesstionBox::-webkit-scrollbar
{
    width: 3px;
    background-color: #F5F5F5;
}

.sugesstionBox::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #e0e0e0;
    border: 2px solid #e0e0e0;
}
.searchCardDiv
{
    /* display: flex;
    position: relative; */
    background: rgb(255, 255, 255);
    padding: 5px 5px;
    min-height: 75px;
    max-height: 80px;
}
.searchCardTitle
{
    margin-bottom: 3px;
    font-size: 15px;
    color: #000000;
}
.searchCardType
{
    font-size: 13px;
    color: #F3766A;
}
.userSearchInput
{
    border: 1px solid #f0f0f0;
}
.dietDiv
{
	padding-right: 0;
	width: 145px;
}
.mealTabsDiv
{
	padding-left: 0;
	width: calc(70% - 145px);
}
	@media (max-width: 991px){
		#modal-viewDish___BV_modal_content_
		{
			max-height: 530px;
		}
		.mealTabsDiv
		{
			padding-left: 0;
			width: calc(100% - 145px);
		}
	}
	#modal-viewDish___BV_modal_body_::-webkit-scrollbar
		{
			width: 3px;
			background-color: #F5F5F5;
		}

		#modal-viewDish___BV_modal_body_::-webkit-scrollbar-thumb
		{
			border-radius: 10px;
			background-color: #e0e0e0;
			border: 2px solid #e0e0e0;
		}
		.custom-control-label
		{
			display: inline;
		}
</style>