export default {

    mounted()
    {
        if (this.$userData !== null) {
			this.userID = this.$userData.userID;
			this.corporateBranchID = this.$userData.corporateBranchID;
		}
    },

    addItemTocartApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, itemFromModal, selectedAddOnItems, mealTypeID)
		{
			// if(this.$isUserLoggedIn  == 1)
			// {
				var found = this.cartItemsDetails.some(el => el.cuisineID === cusineDataArr.id);
				if(found == true)
				{
					var itemFrom = 0;
					if(isItemFromModal == 1)
					{
						itemFrom = 2;
						cusineDataArr.quantity = itemFromModal;
					}
					this.updateItemToCart(cusineDataArr, typeIndex, cusineIndex, itemFrom, selectedAddOnItems);
				}
				else
				{
					if(this.itemFromModal > 0 && this.itemFromModal >= cusineDataArr.quantity && isItemFromModal == 1)
						{
							var cusineQuantity = itemFromModal;
							this.itemFromCard = cusineQuantity;
						}
						else if(itemFromModal == 0 && isItemFromModal == 1) // if item is from modal and quantity is 0
						{
							alert("please select atleast one item to add in cart");
							return false;
						}
						else if(isItemFromModal == 0 && cusineDataArr.quantity == 0) // if  + ADD button pressed
						{
							if(cusineDataArr.mealType == 2 || cusineDataArr.mealType == 3)
							{
								var cusineQuantity = parseInt(cusineDataArr.minorderquantity);
							}
							else
							{
								var cusineQuantity = 1;
							}
						}
						else
						{
							var cusineQuantity = 0;
						}
						var corpMealID = 0;
						var corpMealPlanID = 0
						if(mealTypeID == -2)
						{
							var corpMealID = cusineDataArr.corpMealID;
							var corpMealPlanID = cusineDataArr.corpMealPlanID;
						}
						
						// Add to cart API
						let addItemToCartData = JSON.stringify({ 
							//userID: this.userID,
																cuisineID: cusineDataArr.id,
																quantity: cusineQuantity,
																addOnItems:selectedAddOnItems,
																mealTypeID: mealTypeID, //-2 =>corporatemeals,
																corpMealPlanID: corpMealPlanID,
																corpMealID: corpMealID,
																token: ''
																});
						this.selectedAddOnItems = []; // null value of previously selected addons
						this.axios.post('/addToCart', addItemToCartData)
							.then((response)=>{
								// console.log(response);
								let status = response.status;
							if(status == 200 && response.data.status == 200)
							{
								if(response.data.cartDetails !== '' && response.data.cartItems !== '')
								{
									var newQuantity = cusineQuantity;
									this.list[typeIndex].data[cusineIndex].quantity = newQuantity;
									this.loadCartDetails(response.data.cartDetails, response.data.cartItems);
									// console.log('inadd to cart');
								}
								else
								{
									this.cartDetails = [];
									this.cartItemsDetails = [];
								}
							}
							else
							{
								alert(response.data.message);
							}
								// this.loadCartDetails(response.data.cartDetails, response.data.cartItems);
							}).catch((error) => {
									// console.log(error.response.data.message);
									this.error = "Something Went Wrong";
									return error;
							});
				}
			// }
			// else
			// {
			// 	this.$bvModal.show("modal-login");
			// }
				// let formData = new FormData();  
				// 	 formData.append('cuisineid', cusineDataArr.id);
				// 	 formData.append('selectedaddons', '');
				// 	 formData.append('userid', 3576);
				// 	 formData.append('quantity', cusineQuantity);
				// 	 formData.append('token', '');
				// 	Vue.axios.post('/_addToCart', formData)
				// 	.then((response)=>{
				// 		console.log(response);
				// 		this.cartDetails = response.data.cartdetails;
				// 		this.cartStatus = response.data.cartdetails;
				// 	}).catch((error) => {
				//             console.log(error.response.data.message);
				//     });
		},
        updateItemToCartApi(cusineDataArr, typeIndex, cusineIndex, itemFrom, selectedAddOnItems)
        {
			// itemFrom = 0=> from card, 1=>cart, 2=>modal, 3=>Remove Item 
			
            if(itemFrom == 0) //is item from card
			{
				// console.log(this.cusineDataArr);
				var newQuantity = cusineDataArr.quantity;
				var itemDishID = cusineDataArr.id;
				var restBranchID = cusineDataArr.restaurantid;
			}
			else if(itemFrom == 1)
			{
				var newQuantity = cusineDataArr.quantity;
				var itemDishID = cusineDataArr.cuisineID;
				var restBranchID = cusineDataArr.restBranchID;
			}
			else if(itemFrom == 2)
			{
				var newQuantity = cusineDataArr.quantity;
				var itemDishID = cusineDataArr.id;
				var restBranchID = cusineDataArr.restaurantid;
			}
			else if(itemFrom == 3) // remove Item from cart
			{
				var newQuantity = 0;
				var itemDishID = cusineDataArr.id;
				var restBranchID = cusineDataArr.restaurantid;
			}
			

			if((cusineDataArr.mealType == 2 || cusineDataArr.mealType == 3) && newQuantity < cusineDataArr.minorderquantity)
			{
				// this.$confirm("Are you sure you want to remove this item from? You have to Order minimum "+cusineDataArr.minorderquantity+" per pax", "", "question")
				// .then(() => {
					var qunatityToUpdate = 0;
					// updateItemInCartAPI(qunatityToUpdate, itemDishID, restBranchID);
				// });
			}
			else
			{
				var qunatityToUpdate = newQuantity;
				// updateItemInCartAPI(qunatityToUpdate, itemDishID, restBranchID);
			}
			// console.log(cusineDataArr);
			// alert(this.itemFromCard);

			let getCartDetailsData = JSON.stringify({ 
				//userID: this.userID,
				dishID: itemDishID,
				quantity: qunatityToUpdate,
				addOnItems:selectedAddOnItems,
				restBID: restBranchID,
			  });
			  this.selectedAddOnItems = []; // null value of previously selected addons
			this.axios.post('/updateCart', getCartDetailsData)
			.then((response)=>{
			// console.log(response);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
			let updatedData = response.data.updatedData;
			// find index for cards
			this.loadCartDetails(response.data.cartDetails, response.data.cartItems);
			this.list.findIndex((element, cuisineindex) => {
			if (element.categoryid === updatedData.categoryID) {
			this.cuisineIndexforUpdate = cuisineindex;
			}
			});
			// if(this.cuisineIndexforUpdate)
			// {
			this.list[this.cuisineIndexforUpdate].data.findIndex((element, dishindex) => {
			if (element.id === updatedData.dishID) {
			this.dishIndexForUpdate = dishindex;
			}
			});
			// }
			// console.log(this.cuisineIndexforUpdate);
			// console.log(this.dishIndexForUpdate);
			this.list[this.cuisineIndexforUpdate].data[this.dishIndexForUpdate].quantity = updatedData.newQuantity;

			// find index for cart Items
			this.cartItemsDetails.findIndex((element, cartCuisineindex) => {
			if (element.cuisineID === updatedData.dishID) {
			this.cartCuisineIndexforUpdate = cartCuisineindex;
			}
			});
			this.cartItemsDetails[this.cartCuisineIndexforUpdate].quantity = updatedData.newQuantity;
			this.cartItemsDetails[this.cartCuisineIndexforUpdate].itemTotal = updatedData.newPrice;
			// console.log(this.cartItemsDetails[this.cartCuisineIndexforUpdate].itemTotal);

			// console.log(this.list[this.cuisineIndexforUpdate].data[this.dishIndexForUpdate].quantity); //= newQuantity;

			// this.getCartDetails();
			// this.getCafeMenuByID();
			// console.log(this.list[typeIndex].data[cusineIndex]);
			// this.loadCartDetails(response.data.cartDetails, response.data.cartItems);
			}
			// this.cartStatus = response.data.cartdetails;
			}).catch((error) => {
			console.log(error);
			});
			this.cusineModalShow = false;
        },

		updateItemInCartAPI(qunatityToUpdate, itemDishID, restBranchID)
		{
			let getCartDetailsData = JSON.stringify({ userID: this.userID,
				dishID: itemDishID,
				quantity: qunatityToUpdate,
				restBID: restBranchID,
			  });
			this.axios.post('/updateCart', getCartDetailsData)
			.then((response)=>{
			// console.log(response);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
			let updatedData = response.data.updatedData;
			// find index for cards
			this.list.findIndex((element, cuisineindex) => {
			if (element.categoryid === updatedData.categoryID) {
			this.cuisineIndexforUpdate = cuisineindex;
			}
			});
			// if(this.cuisineIndexforUpdate)
			// {
			this.list[this.cuisineIndexforUpdate].data.findIndex((element, dishindex) => {
			if (element.id === updatedData.dishID) {
			this.dishIndexForUpdate = dishindex;
			}
			});
			// }
			// console.log(this.cuisineIndexforUpdate);
			// console.log(this.dishIndexForUpdate);
			this.list[this.cuisineIndexforUpdate].data[this.dishIndexForUpdate].quantity = updatedData.newQuantity;

			// find index for cart Items
			this.cartItemsDetails.findIndex((element, cartCuisineindex) => {
			if (element.cuisineID === updatedData.dishID) {
			this.cartCuisineIndexforUpdate = cartCuisineindex;
			}
			});
			this.cartItemsDetails[this.cartCuisineIndexforUpdate].quantity = updatedData.newQuantity;
			this.cartItemsDetails[this.cartCuisineIndexforUpdate].itemTotal = updatedData.newPrice;
			// console.log(this.cartItemsDetails[this.cartCuisineIndexforUpdate].itemTotal);

			// console.log(this.list[this.cuisineIndexforUpdate].data[this.dishIndexForUpdate].quantity); //= newQuantity;

			// this.getCartDetails();
			// this.getCafeMenuByID();
			// console.log(this.list[typeIndex].data[cusineIndex]);
			this.loadCartDetails(response.data.cartDetails, response.data.cartItems);
			}
			// this.cartStatus = response.data.cartdetails;
			}).catch((error) => {
			console.log(error.response.data.message);
			});
		},

		getRestaurantsDetailsApi(latitude,longitude, mealTypeID, foodType, isFilterApplied, appliedFilterDetailsArr)
		{
            // var type = "";
			this.shimmer = 0;
			// alert(mealTypeID);
			var appliedFilterArr = [];
			if(isFilterApplied == 1)
			{
				appliedFilterArr = appliedFilterDetailsArr;
			}
            let getrestaurantsFormData = JSON.stringify({ 
				// userID: this.userID,
														mealType:mealTypeID,
                                                        //  type: type,
														 appliedFilterArr:appliedFilterArr,
														 latitude: latitude,
														 longitude:longitude,
														 foodTypeID:foodType
                                                        });

            this.axios.post('/getrestaurants', getrestaurantsFormData)
            .then((res)=>{
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
					this.shimmer = 1;
					this.restaurantList = res.data.data;
					this.allFoodTypes = res.data.allFoodTypes;
					// this.restCount = res.data.count;
                }else{
                    // console.log(res.data.message);
					this.shimmer = 1;
					this.restaurantList = [];
                    alert(res.data.message);
                    // this.$router.push('/');
                }
            });
		},

		getFoodCourtDetailsApi(foodType, isFilterApplied, appliedFilterDetailsArr, foodCourtID)
		{
			this.shimmer = 0;
			// alert(mealTypeID);
			var appliedFilterArr = [];
			if(isFilterApplied == 1)
			{
				appliedFilterArr = appliedFilterDetailsArr;
			}
            let getFoodcourtsFormData = JSON.stringify({ 
				// userID: this.userID,
														foodCourtID:foodCourtID,
														appliedFilterArr:appliedFilterArr,
														foodTypeID:foodType
                                                        });

            this.axios.post('/getFoodCourt', getFoodcourtsFormData)
            .then((res)=>{
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
					this.shimmer = 1;
					this.restaurantList = res.data.data;
					this.allFoodTypes = res.data.allFoodTypes;
					// this.restCount = res.data.count;
                }else{
                    // console.log(res.data.message);
					this.shimmer = 1;
					this.restaurantList = [];
                    alert(res.data.message);
                    // this.$router.push('/');
                }
            });
		},

		getCorpMealDetailsApi(foodType, isFilterApplied, appliedFilterDetailsArr)
		{
			this.shimmer = 0;
			// alert(foodType);
			var appliedFilterArr = [];
			if(isFilterApplied == 1)
			{
				appliedFilterArr = appliedFilterDetailsArr;
			}
            let getCorpFormData = JSON.stringify({ 
				// userID: this.userID,
														appliedFilterArr:appliedFilterArr,
														foodTypeID:foodType
                                                        });
            this.axios.post('/getCorporateMealRestaurants', getCorpFormData)
            .then((res)=>{
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
					this.shimmer = 1;
					this.restaurantList = res.data.data;
					this.allFoodTypes = res.data.allFoodTypes;
					// this.restCount = res.data.count;
                }else{
                    // console.log(res.data.message);
					this.shimmer = 1;
					this.restaurantList = [];
                    alert(res.data.message);
                    // this.$router.push('/');
                }
            });
		},

		flushCartDataAndAddItemToCart(restBranchIDFlush, restBranchID) // restBranchIDFlush => to flush
		{
			this.flushedcart = 0;
				let getcartFormData = JSON.stringify({ 
					// userID: userID,
					restBranchIDFlush: restBranchIDFlush,
					restBranchID: restBranchID,
				});
			this.axios.post('/flushCartData', getcartFormData)
            .then((res)=>{
                let status = res.status;
                if(status == 200 && res.data.status==200)
                {
					var isCartFlushed = 1;
					// var flushedCartData = {
					// 	"isCartFlushed":1,
					// 	"message":res.data.message,
					// }
					// return 1;
					// return JSON.stringify(flushedCartData);
					// console.log(this.flushedCart);
                }else{
                    // console.log(res.data.message);
					// var flushedCartData = {
					// 	"isCartFlushed":0,
					// 	"message":res.data.message,
					// }
					// return JSON.stringify(flushedCartData);
					// return 0;
                    alert(res.data.message);
                    // this.$router.push('/');
                }
            });
		},

		getCusineDetailsApi(cusineDataArr, typeIndex, cusineIndex)
		{
			this.cusineDetailsDataArr = cusineDataArr;
			if(cusineDataArr.quantity == 0 )
			{
				this.itemFromModal = 1;
			}
			else
			{
				this.itemFromModal = parseInt(cusineDataArr.quantity);
			}
			// console.log(cusineDataArr.isCustomisable);
			if(cusineDataArr.isCustomisable == 1)
			{
				this.getCusineById(cusineDataArr.id, cusineDataArr);
			}
			this.cusineTypeIndex = typeIndex;
			this.cusineItemIndex = cusineIndex;
			this.itemTotalPopup = cusineDataArr.price;
			this.selectedAddOnItems = [];
			this.cusineModalShow = true;
		},

		getCusinieByIDApi(cusineId, userID, cusineDataArr)
		{
			let getCuisineDetails = JSON.stringify({ 
				// userID: userID,
				cuisineID: cusineId,
				});
			this.axios.post('/getCuisineDetailsByID', getCuisineDetails)
			.then((response)=>{
			// console.log(response.data.cuisineDetails);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
				this.cuisineItemDetails = response.data.cuisineDetails;
				if(response.data.cartSelectedAddons.length >= 0)
				{
					this.selectedAddOnItems = response.data.cartSelectedAddons;
					var sum = this.selectedAddOnItems.reduce((acc, item) => acc + item.price, 0);
					this.itemTotalPopup = (parseInt(cusineDataArr.price) + parseInt(sum) )* parseInt(this.itemFromModal);
				}

			}
			// this.cartStatus = response.data.cartdetails;
			}).catch((error) => {
			console.log(error.response.data.message);
			});
		},

		getBannerByMealTypeApi(menuTab,restBranchID)
        {
            this.bannerShimmer = 0;
            let getBannerFromMealFormData = JSON.stringify({ menuIdentifier: menuTab,restBranchID:restBranchID
                                                            });

                this.axios.post('/getBannersForMealType', getBannerFromMealFormData)
                .then((res)=>{
                    let status = res.status;
                    if(status == 200 && res.data.status==200)
                    {
                        this.bannerShimmer = 1;
                        this.bannerDetailsArr = res.data.bannerDetails;
                        // console.log(res.data.bannerDetails)                        
                    }
                    else{
                        // console.log(res.data.message);
                        alert(res.data.message);
                        // this.$router.push('/');
                    }
                }).catch((error) => {
                            this.bannerShimmer = 0;
                            console.log(error.response.data.message);
                        });
        },
		getCouponsByIDApi(restBranchID, mealTypeID)
		{
			let getgetCouponsByIDFormData = JSON.stringify({ 
				// userID: userID,
															 restBranchID: restBranchID,
															 mealTypeID: mealTypeID
			});

			this.axios.post('/getCoupons', getgetCouponsByIDFormData)
			.then((res)=>{
			let status = res.status;
			if(status == 200 && res.data.status==200)
			{
			this.couponsDetailsArr = res.data.couponsData;
			// console.log(res.data.bannerDetails)                        
			}
			else{
			// console.log(res.data.message);
			alert(res.data.message);
			// this.$router.push('/');
			}
			}).catch((error) => {
			// this.bannerShimmer = 0;
			console.log(error.response.data.message);
			});
		},

		applyCouponsApi(couponID,restBranchID)
		{
			let getgetCouponsByIDFormData = JSON.stringify({ 
				// userID: userID,
															couponID: couponID,
															restBranchID: restBranchID
			});

			this.axios.post('/applyCoupons', getgetCouponsByIDFormData)
			.then((res)=>{
			let status = res.status;
			if(status == 200 && res.data.status==200)
			{
			// this.couponsDetailsArr = res.data.couponsData;
			// console.log(res.data.bannerDetails)                        
			}
			else{
			// console.log(res.data.message);
			consoel.log(res.data.message);
			// this.$router.push('/');
			}
			}).catch((error) => {
			console.log(error.response.data.message);
			});
		},

		getVouchersByIDApi(restBranchID)
		{
			let getVouchersByIDFormData = JSON.stringify({ 
				// userID: userID,
															 restBranchID: restBranchID
			});

			this.axios.post('/getVouchers', getVouchersByIDFormData)
			.then((res)=>{
			let status = res.status;
			if(status == 200 && res.data.status==200)
			{
			this.vouchersDetailsArr = res.data.vouchersData;
			// console.log(this.vouchersDetailsArr);
			}
			else{
			// console.log(res.data.message);
			alert(res.data.message);
			// this.$router.push('/');
			}
			}).catch((error) => {
			// this.bannerShimmer = 0;
			console.log(error.response.data.message);
			});
		},

		arrayColumn(array, columnName) {
			return array.map(function(value,index) {
				return value[columnName];
			})
		},

		getAllFiltersApi()
		{
			this.axios.get('/getAllFilters')
                .then((res)=>{
					// console.log(res);
                    let status = res.status;
                    if(status == 200 && res.data.status==200)
                    {
                        this.allFilters = res.data.data;
                        // console.log(res.data.bannerDetails)                        
                    }
                    else{
                        console.log(res.data.message);
                    }
                }).catch((error) => {
                            console.log(error.response.data.message);
                        });
		},
    
  };